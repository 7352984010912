<!-- Content Header (Page header) -->
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">{{ title }}</h1>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li
            *ngFor="let crumb of crumbs; let i = index"
            class="breadcrumb-item"
            [ngClass]="{ active: crumb.active }"
          >
            <a
              *ngIf="!crumb.active"
              [routerLink]="crumb.path"
              routerLinkActive="router-link-active"
              >{{ crumb.title }}</a
            ><span *ngIf="crumb.active">
              {{ crumb.title }}
            </span>
          </li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
