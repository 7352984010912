export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBrJaiS8uqXVO4gOg60bp63ZV3tcmR_sCU",
    authDomain: "fibie-b726e.firebaseapp.com",
    databaseURL: "https://fibie-b726e.firebaseio.com",
    projectId: "fibie-b726e",
    storageBucket: "fibie-b726e.appspot.com",
    messagingSenderId: "387991783275",
    appId: "1:387991783275:web:f2581e979f5f9a83372e2e",
    measurementId: "G-C4KYLYDWHJ",
  },
};
