import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainComponent } from "./template/main/main.component";
import { LoginComponent } from "./pages/login/login.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { AuthGuard } from "./services/auth.guard";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "", pathMatch: "full", redirectTo: "home/dashboard" },
  {
    path: "home",
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "contact", component: ContactComponent }
    ]
  },
  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
