import { DOCUMENT } from "@angular/common";
import { Inject, Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { MessagingService } from "../../services/messaging.service";

declare const App: any;

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"]
})
export class MainComponent implements OnInit {
  message;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private messagingService: MessagingService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.setupFcmMessages();

    this.renderer.addClass(this.document.body, "sidebar-mini");
    this.renderer.addClass(this.document.body, "layout-fixed");

    //$.widget.bridge("uibutton", $.ui.button);

    App.initLayout();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, "sidebar-mini");
    this.renderer.removeClass(this.document.body, "layout-fixed");
  }

  setupFcmMessages() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  }
}
