import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AsyncPipe } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireMessagingModule } from "@angular/fire/messaging";

import { MessagingService } from "./services/messaging.service";

import { environment } from "../environments/environment";
import { HeaderComponent } from "./template/header/header.component";
import { MenuComponent } from "./template/menu/menu.component";
import { FooterComponent } from "./template/footer/footer.component";
import { PageTitleComponent } from "./template/page-title/page-title.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { LoginComponent } from "./pages/login/login.component";
import { MainComponent } from "./template/main/main.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    PageTitleComponent,
    NotFoundComponent,
    DashboardComponent,
    ContactComponent,
    LoginComponent,
    MainComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireMessagingModule
  ],
  providers: [MessagingService, AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
