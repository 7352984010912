import { Component, OnInit, Input } from "@angular/core";
import { Crumbs } from "../../types/crumbs.model";

@Component({
  selector: "app-page-title",
  templateUrl: "./page-title.component.html",
  styleUrls: ["./page-title.component.scss"]
})
export class PageTitleComponent implements OnInit {
  @Input() title: string;
  @Input() crumbs: Crumbs[];

  constructor() {}

  ngOnInit(): void {}
}
