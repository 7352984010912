<footer class="main-footer">
  <strong>Copyright &copy; 2020 Ozaccom.</strong>
  All rights reserved.
  <div class="float-right d-none d-sm-inline-block"><b>Version</b> 3.0.2</div>
</footer>

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
  <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->
