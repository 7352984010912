import { Component, OnInit } from "@angular/core";
import { Crumbs } from "../../types/crumbs.model";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  title: String = "Dashboard";
  crumbs: Crumbs[] = [];

  ngOnInit() {
    this.crumbs.push({ title: "Dashboard", path: "/", active: false });
    this.crumbs.push({ title: "Users", path: "/users", active: true });
  }
}
