<div class="login-box">
  <div class="login-logo">
    <a href="../../index2.html"><b>Fibie</b>Admin</a>
  </div>
  <!-- /.login-logo -->
  <div *ngIf="auth.user$ | async as user; else login">
    {{ user | json }}
  </div>

  <ng-template #login>
    <div *ngIf="error" class="alert alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-hidden="true"
      >
        ×
      </button>
      <h4><i class="icon fa fa-ban"></i> Error logging in!</h4>
      {{ error }}
    </div>
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Sign in to start your session</p>
        <form>
          <div class="input-group mb-3">
            <input
              [(ngModel)]="credentials.email"
              type="email"
              name="email"
              class="form-control"
              placeholder="Email"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              [(ngModel)]="credentials.password"
              type="password"
              name="password"
              class="form-control"
              placeholder="Password"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember" />
                <label for="remember">
                  Remember Me
                </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button
                type="button"
                class="btn btn-primary btn-block"
                (click)="emailSignIn()"
              >
                Sign In
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>

        <div class="social-auth-links text-center mb-3">
          <p>- OR -</p>
          <button class="btn btn-block btn-primary" (click)="facebookSignIn()">
            <i class="fab fa-facebook mr-2"></i> Sign in with Facebook
          </button>
          <button class="btn btn-block btn-danger" (click)="googleSignIn()">
            <i class="fab fa-google-plus mr-2"></i> Sign in with Google
          </button>
        </div>
        <!-- /.social-auth-links -->

        <p class="mb-1">
          <a href="forgot-password.html">I forgot my password</a>
        </p>
        <p class="mb-0">
          <a href="register.html" class="text-center"
            >Register a new membership</a
          >
        </p>
      </div>
      <!-- /.login-card-body -->
    </div>
  </ng-template>
</div>
<!-- /.login-box -->
