import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { Inject, Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";

import { AuthService } from "../../services/auth.service";

declare const App: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  private user;
  credentials: any = {
    email: "",
    password: ""
  };
  error;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private renderer: Renderer2,
    public auth: AuthService
  ) {
    this.user = auth.user$.subscribe(u =>
      u ? this.router.navigate(["/home/dashboard"]) : null
    );
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, "login-page");
    App.initLayout();
  }

  ngOnDestroy(): void {
    console.log("destroy");
    this.user.unsubscribe();
    this.renderer.removeClass(this.document.body, "login-page");
  }

  googleSignIn() {
    this.auth.googleSignIn().catch(err => {
      this.error = err.message;
    });
  }

  facebookSignIn() {
    this.auth.facebookSignIn().catch(err => {
      this.error = err.message;
    });
  }

  emailSignIn() {
    this.auth.emailSignIn(this.credentials).catch(err => {
      this.error = err.message;
    });
  }
}
